import React, {useState} from 'react'
import {signOut, signIn, fetchAuthSession} from 'aws-amplify/auth';
import {Link, useNavigate} from 'react-router-dom';
import {getProfile} from "../services/UserService";
import '../stylesheets/Login.css';
import openEye from "../icons/eye-open.png";
import closedEye from "../icons/eye-closed.png";


const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showError, setShowError] = useState(false);
    const navigate = useNavigate();

    const createUser = (event) => {
        event.preventDefault();
        setShowError(false);
        if (email && password) {
            signOut().then(() => {
                signIn({
                    password: password,
                    username: email,
                    options: {
                        authFlowType: 'USER_PASSWORD_AUTH',
                    },
                })
                    .then(() => {
                        fetchAuthSession().then(session => {
                            getProfile(session.tokens.idToken.toString())
                                .then((user) => {
                                    localStorage.setItem('business_profile', JSON.stringify(user.data))
                                    localStorage.setItem('token', session.tokens.idToken.toString())
                                    navigate('/');
                                })
                        })
                    })
                    .catch(err => {
                        console.log(err)
                        setShowError(true);
                    });
            })
        }
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    return (
        <div className="w-50 mx-auto login-div">
            <h3 className={"font-bold"}>Good to see you again!</h3>
            <a href="https://dupe-staging-dev.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=Google&response_type=code&client_id=3amaur3hqgljj17sfd00bprn05&redirect_uri=http://localhost:3000/oauth/">
                <img className={"google-logo"}
                     src={"https://dupe-photos.s3.amazonaws.com/btn_google_signin_light_normal_web%402x.png"} alt={""}/>
            </a>

            <p>OR</p>
            {showError &&
                <p className={"text-danger"}>Username or Password is incorrect</p>
            }
            <form className="row g-3">
                <div className="col-12">
                    <input type="text" className="form-control" id="email" value={email}
                           onChange={(e) => setEmail(e.target.value)} placeholder="Email"/>
                </div>
                <div className="col-md-12 password-div">
                    <input type={showPassword ? "text" : "password"} className="form-control" id="password"
                           value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password"/>
                    {showPassword &&
                        <img className={"password-eye-img"} onClick={toggleShowPassword} src={closedEye} alt={""}/>
                    }
                    {!showPassword &&
                        <img className={"password-eye-img"} onClick={toggleShowPassword} src={openEye} alt={""}/>
                    }
                </div>
                <div className="col-12">
                    <button className="btn btn-primary login-page-btn" type="submit" onClick={createUser}>Login</button>
                </div>
                <div className="col-md-12">
                    <Link to="/forgot_password">
                        <p>Forgot Password?</p>
                    </Link>
                </div>
            </form>
        </div>

    );
}

export default Login