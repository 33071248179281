import axios from "axios";

export async function saveAccessToken(req, token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/users/access_token";
    const result = await axios.post(url, req, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getProfile(token) {
    const result = await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/profile', { headers: {'X-CLIENT-ID': token}})
    return result;
}