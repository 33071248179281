import axios from "axios";

export async function getContent() {
    let url = "https://content-api-prod-6gxsdymdsq-ue.a.run.app/api/v1/content"
    return await axios.get(url);
}

export async function getContent2() {
    let url = "https://content-api-prod-6gxsdymdsq-ue.a.run.app/api/v1/content?page=2"
    return await axios.get(url);
}