import React, {useEffect, useLayoutEffect, useState} from 'react'
import {
    DndContext,
    DragOverlay,
    KeyboardSensor,
    PointerSensor,
    useDraggable,
    useDroppable,
    useSensor, useSensors
} from '@dnd-kit/core';
import "../stylesheets/Grid.css";
import {getSocialAccounts} from "../services/BusinessService";
import {getContent, getContent2} from "../services/ContentService";
import {
    arrayMove, rectSortingStrategy,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy
} from "@dnd-kit/sortable";
import {CSS} from '@dnd-kit/utilities';
import {useNavigate} from "react-router-dom";
import plusIcon from "../icons/grey-plus-btn.png";

export function SortableItem(props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({
        id: props.id,
        data: props.image
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div className={"post-container"} ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {props.children}
        </div>
    );
}

export function NewItem(props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useDraggable({
        id: props.id,
        data: props.image
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div className={"new-content-div"} ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {props.children}
        </div>
    );
}

const Grid = () => {

    const [isDropped, setIsDropped] = useState(false);
    const [images, setImages] = useState([]);
    const [popularContent, setPopularContent] = useState([]);
    const [instagramAccounts, setInstagramAccounts] = useState([]);
    const [selectedInstagram, setSelectedInstagram] = useState({});
    const [activeImage, setActiveImage] = useState(null);
    const [contentTabOpen, setContentTabOpen] = useState(false);
    const [displayPopular, setDisplayPopular] = useState(false);
    const [displayUploaded, setDisplayUploaded] = useState(false);
    const navigate = useNavigate();
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    function toggleDisplayPopular() {
        setDisplayPopular(!displayPopular);
    }

    function toggleDisplayUploaded() {
        setDisplayUploaded(!displayUploaded);
    }

    useLayoutEffect(()=>{
        const token = localStorage.getItem("token");
        getSocialAccounts(token)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setInstagramAccounts(resp.data.instagramAccounts);
                    if (resp.data.instagramAccounts.length > 0) {
                        setSelectedInstagram(resp.data.instagramAccounts[0])
                    }
                }
            })
            .catch(err => {
                if (err.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem('business_profile')
                    navigate('/login')
                }
            })
        getContent()
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setImages(resp.data);
                }
            })
        getContent2()
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setPopularContent(resp.data);
                }
            })

    }, []);

    return (
        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
            <div className={"grid-container " + (contentTabOpen ? "small-grid-margin" : "large-grid-margin")}>
                <div className={"instagram-grid-header-div"}>
                    <img className={"facebook-profile-img"} src={selectedInstagram.profileImageUrl} />
                    <h5>{selectedInstagram.name}</h5>
                    <img className={"add-post-img"} src={plusIcon} onClick={event => setContentTabOpen(true)} />
                </div>
                {images && images.length > 0 && (
                    <SortableContext
                        items={images}
                        strategy={rectSortingStrategy}
                    >
                        {images.map(image =>
                            <SortableItem key={image.id} id={image.id} image={image}>
                                <img src={"data:" + image.image_type + ";base64," + image.img} className={"grid-img"} />
                            </SortableItem>
                        )}
                    </SortableContext>
                )}
                {/*<DragOverlay>*/}
                {/*    {activeImage ? (*/}
                {/*        <SortableItem key={activeImage.id} id={activeImage.id} image={activeImage}>*/}
                {/*            <img src={"data:" + activeImage.image_type + ";base64," + activeImage.img} className={"grid-img"} />*/}
                {/*        </SortableItem>*/}
                {/*    ): null}*/}
                {/*</DragOverlay>*/}
            </div>
            {contentTabOpen && (
                <div className={"grid-content-div"}>
                    <div className={"new-content-header-div"}>
                        <h3 onClick={event => setContentTabOpen(false)}>X</h3>
                        <h3>Add New Content</h3>
                    </div>
                    <div>
                        <h5 onClick={toggleDisplayUploaded}>Uploaded</h5>
                        {displayUploaded && (
                            <div>
                                <p>hi</p>
                            </div>
                        )}
                    </div>
                    <div>
                        <h5>Your Collections</h5>
                    </div>
                    <div>
                        <h5 onClick={toggleDisplayPopular}>Popular Content</h5>
                        {displayPopular && (
                            <div>
                                {popularContent.map(image =>
                                    <NewItem key={"c" + image.id} id={"c" + image.id} image={image}>
                                        <img src={"data:" + image.image_type + ";base64," + image.img} className={"new-content-img"} />
                                    </NewItem>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
            {activeImage && (
                <DragOverlay>
                    <NewItem key={"c" + activeImage.id} id={"c" + activeImage.id} image={activeImage}>
                        <img src={"data:" + activeImage.image_type + ";base64," + activeImage.img} className={"new-content-img"} />
                    </NewItem>
                </DragOverlay>
            )}

        </DndContext>
    );

    function getIndex(id) {
        return images.findIndex(image => image.id === id);
    }
    function handleDragStart(event) {
        setActiveImage(event.active.data.current);
    }

    function handleDragEnd({active, over}) {
        setActiveImage(null);
        if (over && getIndex(over.id) > 0) {
            const overIndex = getIndex(over.id);
            if (getIndex(activeImage.id) < 0) {
                setImages(images.slice(0, overIndex).concat(activeImage, images.slice(overIndex, images.length)));
            } else {
                const activeIndex = getIndex(activeImage.id);
                if (activeIndex !== overIndex) {
                    setImages((items) => arrayMove(items, activeIndex, overIndex));
                }
            }
        }
    }
}

export default Grid
