import React, {useEffect, useState} from 'react'
import "../stylesheets/Outreach.css";
import {useNavigate} from "react-router-dom";
import {getCampaigns} from "../services/CampaignService";
import plusIcon from "../icons/grey-plus-btn.png";
import { View, ScrollView, StyleSheet, Text, TouchableOpacity, Image } from 'react-native-web';
import CampaignPreview from './CampaignPreview';
import Header from './Header';
const campaignTemplate = {
    id: 'template',
    name: 'Campaign Name',
}

const Outreach = () => {
    const navigate = useNavigate();
    const [campaigns, setCampaigns] = useState([])

    useEffect(()=>{
        const token = localStorage.getItem("token");
        getCampaigns(token)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setCampaigns(resp.data);
                }
            })

    }, []);

    return(
        <View style={{height: '100vh'}}>
            <Header title="OUTREACH" />
            <View>
                <View style={styles.containerLabelDiv}>
                    <View style={styles.headerContainer}>
                        <Text style={styles.blueText}>Active Campaigns</Text>
                        <TouchableOpacity onPress={() => navigate('/campaigns/create')}>
                            <Image source={plusIcon} style={styles.addCampaignImg} />
                        </TouchableOpacity>
                    </View>
                    <Text style={styles.descriptionText}>
                        Campaigns are for Influencers, people that will share your product on their social platforms to reach a wider audience and drive sales through content
                    </Text>
                </View>
                {!campaigns || campaigns.length < 1 ? <CampaignPreview campaign={campaignTemplate} /> :
                <ScrollView>
                    <View>
                        {campaigns.map(campaign => (
                            <CampaignPreview key={campaign.id} campaign={campaign} />
                        ))}
                    </View>
                </ScrollView>
                }
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    containerLabelDiv: {
        marginLeft: '2.5%',
        marginRight: '2.5%',
        marginBottom: 20,
    },
    headerContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    blueText: {
        color: '#396de0',
        fontFamily: "'forma-djr-display', sans-serif",
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    descriptionText: {
        fontSize: 14,
        color: '#737373',
        fontFamily: "'lato', sans-serif",
        marginBottom: 10,
    },
    addCampaignImg: {
        width: 20,
        height: 20,
        alignSelf: 'flex-start',
    },
});

export default Outreach
