import axios from "axios";

export async function getPopularCreators() {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/creators/popular";
    const result = await axios.get(url);
    return result;
}

export async function searchCreators(req, token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/creators/search";
    const result = await axios.post(url, req, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getUsersPopularPosts(userId, token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/creators/" + userId + "/content/popular";
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getCreatorsContent(userId, token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/creators/" + userId + "/content";
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getCreatorsContentByAesthetic(userId, aesthetic, token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/creators/" + userId + "/content?aesthetic=" + aesthetic;
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getCreatorsAesthetics(userId, token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/creators/" + userId + "/aesthetics";
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}
