import React, {useState} from 'react'


const CreateProject = () => {

    return(
        <div className="w-50 mx-auto login-div">
            <h4>Hi</h4>
        </div>

    );
}

export default CreateProject
