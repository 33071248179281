import React, { useEffect, useState } from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet, ScrollView } from 'react-native-web';
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import dupeLogo from "../icons/Dupe_Logo_White.png";
import yellowNetworkIcon from "../icons/yellow-network-icon.png";
import yellowMagnifyingIcon from "../icons/yellow-magnifying-icon.png";
import yellowLandscapeIcon from "../icons/yellow-landscape-icon.png";
import yellowAnnouncementIcon from "../icons/yellow-announcement-icon.png";
import yellowGridIcon from "../icons/yellow-grid-icon.png";

const SideBar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState("");

    const logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem('business_profile');
        navigate('/login');
    };

    useEffect(() => {
        setCurrentPath(location.pathname);
    }, [location]);

    const renderMenuItem = (to, icon, text) => (
        <Link to={to} style={styles.link}>
            <View style={[styles.menuItem, currentPath === to && styles.selectedMenuItem]}>
                <Image source={icon} style={styles.icon} />
                <Text style={styles.menuText}>{text}</Text>
            </View>
        </Link>
    );

    return (
        <View style={styles.container}>
            <View style={styles.sidebar}>
                <View style={styles.logoContainer}>
                    <Image source={dupeLogo} style={styles.logo} />
                    <Text style={styles.logoText}>Biz</Text>
                </View>
                <View style={styles.menu}>
                    <View>
                        {renderMenuItem("/home", yellowNetworkIcon, "Brand Hub")}
                        {renderMenuItem("/creators/search", yellowMagnifyingIcon, "Find Creators")}
                        {renderMenuItem("/content", yellowLandscapeIcon, "Content")}
                        {renderMenuItem("/outreach", yellowAnnouncementIcon, "Campaigns")}
                    </View>
                    <View>
                        {renderMenuItem("/account", yellowGridIcon, "Account")}
                        <TouchableOpacity onPress={logout} style={styles.menuItem}>
                        <Image source={yellowGridIcon} style={styles.icon} />
                            <Text style={styles.menuText}>Logout</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
            <ScrollView style={styles.content}>
                <Outlet />
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        height: "100vh",
    },
    sidebar: {
        width: 250,
        backgroundColor: '#396de0',
        padding: 20,
    },
    logoContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginBottom: 30,
    },
    logo: {
        width: 120,
        height: 50,
        resizeMode: 'contain',
    },
    logoText: {
        fontSize: 50,
        fontFamily: "'forma-djr-display', sans-serif",
        color: 'white',
    },
    menu: {
        flex: 1,
        justifyContent: 'space-between',
    },
    menuItem: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 15,
        marginBottom: 5,
    },
    selectedMenuItem: {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    icon: {
        width: 20,
        height: 20,
        marginRight: 10,
    },
    menuText: {
        color: 'white',
        fontSize: 16,
        textDecoration: 'none',
        fontFamily: "'lato', sans-serif",
    },
    link: {
        textDecoration: 'none',
    },
    content: {
        flex: 1,
    },
});

export default SideBar;
