import './App.css';
import {Routes, Route, useNavigate} from "react-router-dom";
import {View} from "react-native-web";
import Login from "./components/Login";
import React, {useEffect} from "react";
import SideBar from "./components/SideBar";
import CreatorSearch from "./components/CreatorSearch";
import Account from "./components/Account";
import AccessToken from "./components/AccessToken";
import {Amplify} from "aws-amplify";
import awsconfig from './aws-exports';
import Grid from "./components/Grid";
import BrandHub from "./components/BrandHub";
import Outreach from "./components/Outreach";
import CreateProject from "./components/CreateProject";
import CreateCampaign from "./components/CreateCampaign";
import Campaign from "./components/Campaign";

export default function App() {
    const navigate = useNavigate();

    useEffect(()=>{
        Amplify.configure(awsconfig);
        const profile = localStorage.getItem("business_profile");
        if (!profile) {
            localStorage.removeItem("token");
            navigate('/login')
        }
    }, []);
  return (
      <View style={{height: '100vh', backgroundColor: '#f1f1f1'}}>
        <Routes>
            <Route path="/" element={<SideBar/>}>
                <Route path="home" element={<BrandHub/>} />
                <Route path="creators/search" element={<CreatorSearch/>} />
                <Route path="account" element={<Account/>} />
                <Route path="account/callback" element={<AccessToken/>} />
                <Route path="grid" element={<Grid/>} />
                <Route path="outreach" element={<Outreach/>} />
                <Route path="projects/create" element={<CreateProject/>} />
                <Route path="campaigns/create" element={<CreateCampaign/>} />
                <Route path="campaigns/:campaignSlug" element={<Campaign/>} />
            </Route>
            <Route path="/login" element={<Login />}/>
        </Routes>
    </View>
  );
}
