import React, {useEffect, useState} from 'react'
import {
    createPaymentMethod,
    getBusinessUsers, getPaymentMethods,
    getSocialAccounts,
    getStripeIntent,
    getSubscription,
    inviteUser
} from "../services/BusinessService";
import "../stylesheets/Account.css";
import plusIcon from "../icons/grey-plus-btn.png";
import Modal from "react-modal";
import {loadStripe} from "@stripe/stripe-js";
import {Elements, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import pencilIcon from "../icons/black-pencil.png"
import AccountCard from "./AccountCard";    
import Header from './Header';
import { View, StyleSheet, Text, Image } from 'react-native-web';
const stripePromise = loadStripe('pk_test_51PWON1AzMP8rKeYu5wkeKGqhypzJY243D2DAglj5VakbuJozKaNdMvJ1xfcDVOzNrjMX8iDaMITZJDpqt4CdQPGn00Eu3rjcuc');

const Account = () => {
    const [facebookUrl, setFacebookUrl] = useState("https://www.facebook.com/v17.0/dialog/oauth?response_type=token&display=popup&client_id=1330402327595027&redirect_uri=https%3A%2F%2Fbusiness.dupephotos.com%2Faccount%2Fcallback&auth_type=rerequest&scope=instagram_basic%2Cinstagram_content_publish");
    const [instagramAccounts, setInstagramAccounts] = useState({})
    const [subscription, setSubscription] = useState({})
    const [businessUsers, setBusinessUsers] = useState([])
    const [paymentMethods, setPaymentMethods] = useState([])
    const [options, setOptions] = useState({})
    const [addUserModal, setAddUserModal] = useState(false)
    const [updateCCModal, setUpdateCCModal] = useState(false)
    const [newUser, setNewUser] = useState("")

    const defaultStyle = {
        overlay: {
            backgroundColor: 'rgba(24, 47, 96, 0.80)',
            position: 'fixed',
            zIndex: 999
        }
    };

    function afterOpenModal() {

    }

    function openCCModal() {
        const token = localStorage.getItem("token");
        getStripeIntent(token)
            .then(resp => {
                if (resp.status === 200 && resp.data && resp.data.client_secret) {
                    console.log(resp.data.client_secret);
                    setOptions({
                        clientSecret: resp.data.client_secret,
                        id: resp.data.id
                    });
                    setUpdateCCModal(true);
                }
            })
    }

    function closeModal() {
        setAddUserModal(false);
        setUpdateCCModal(false);
    }

    const updateNewUser = (event) => {
        setNewUser(event.target.value);
    }

    function triggerUserInvite(event) {
        //console.log(event);
        event.preventDefault();
        if (newUser != "") {
            const token = localStorage.getItem("token");
            let req = {};
            req.email = newUser;
            inviteUser(req, token)
                .then(resp => {
                    closeModal();
                })
        }
    }

    function updateCard() {
        const token = localStorage.getItem("token");
        createPaymentMethod(options, token)
            .then(resp => {
                console.log(resp.data);
                if (resp.status === 200 && resp.data) {
                    closeModal();
                }
            })
    }

    useEffect(()=>{
        const token = localStorage.getItem("token");
        getSocialAccounts(token)
            .then(resp => {
                if (resp.status == 200 && resp.data) {
                    setInstagramAccounts(resp.data.instagramAccounts);
                }
            })
        getBusinessUsers(token)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setBusinessUsers(resp.data);
                }
            })
        getSubscription(token)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setSubscription(resp.data);
                }
            })
        getPaymentMethods(token)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    console.log(resp.data);
                    setPaymentMethods(resp.data);
                }
            })

    }, []);
    return(
        <View>
            <Header title="ACCOUNT" />
            <View style={styles.socialAccountsDiv}>
            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                <View>
                    <Text style={styles.sectionText}>Subscription</Text>
                    <View style={styles.subscriptionDiv}>
                        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                            <Text style={styles.blueText}>{subscription.name}</Text>
                            <Text style={styles.priceText}>${subscription.price} / month</Text>
                        </View>
                    </View>
                </View>
                    <View style={styles.cardsDiv}>
                        <Text style={styles.sectionText}>Card on File</Text>
                        <View style={styles.boxDiv}>
                            {paymentMethods && paymentMethods.length > 0 && (
                                <View style={styles.paymentDiv}>
                                    <Text style={styles.paymentText}>&#8226;&#8226;&#8226;&#8226;</Text>
                                    <Text style={styles.paymentText}>{paymentMethods[0].card.last4}</Text>
                                    <Text style={styles.paymentText}>{paymentMethods[0].card.exp_month}/{paymentMethods[0].card.exp_year}</Text>
                                    <Image source={pencilIcon} />
                                </View>
                            )}
                    </View>
                </View>
            </View>
                <div className={"account-users-div"}>
                    <Text style={styles.sectionText}>Account Users</Text>
                    <img className={"add-user-img"} src={plusIcon} onClick={() => {setAddUserModal(true)}} />
                    {businessUsers && businessUsers.length > 0 && businessUsers.map(account  => (
                        <AccountCard key={account.id} account={account} />
                    ))}
                </div>
                <Text style={styles.sectionText}>Linked Instagram Accounts</Text>
                {instagramAccounts && instagramAccounts.length > 0 && instagramAccounts.map(account  => (
                    <div className={"facebook-account-div box-div"} onClick={()=> window.open(facebookUrl, "_blank")}>
                        <img className={"facebook-profile-img"} src={account.profileImageUrl} />
                        <h5>{account.name}</h5>
                    </div>
                ))}
                {(!instagramAccounts || instagramAccounts.length < 1) && (
                    <div onClick={()=> window.open(facebookUrl, "_blank")}>
                        <img />
                        <h5>Connect Your Instagram</h5>
                    </div>
                )}
                <Modal
                    isOpen={addUserModal}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    className={"add-user-modal"}
                    style={defaultStyle}
                >
                    <div>
                        <h3 className={"centered-text"}>Add User</h3>
                        <input type="text" className="form-control grey-input" value={newUser} onChange={updateNewUser} placeholder="User's Email"/>
                        <div className={"edit-profile-footer"}>
                            <button className={"light-grey-btn"}>Cancel</button>
                            <button type="submit" className="btn blue-btn" onClick={triggerUserInvite}>Invite</button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={updateCCModal}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    className={"cc-modal"}
                    style={defaultStyle}
                >
                    <div>
                        <h3>Update Credit Card on File</h3>
                        {options.clientSecret && (
                            <Elements stripe={stripePromise} options={options}>
                                <StripeForm updateCard={updateCard} />
                            </Elements>
                        )}
                    </div>
                </Modal>
            </View>
        </View>

    );
}

const styles = StyleSheet.create({
    socialAccountsDiv: {
        margin: '4%',
    },
    subscriptionDiv: {
        backgroundColor: 'white',
        borderRadius: 8,
        padding: 20,
        marginBottom: 15,
        borderWidth: 1,
        borderColor: '#b8cbf5',
      },
      paymentDiv: {
        backgroundColor: 'white',
        borderRadius: 8,
        padding: 20,
        marginBottom: 15,
        borderWidth: 1,
        borderColor: '#b8cbf5',
        flexDirection: 'row',
      },
      sectionText: {
        fontFamily: "'forma-djr-display', sans-serif",
        fontSize: 30,
        fontWeight: 'bold',
        color: '#396de0',
        marginBottom: 10,
      },
      blueText: {
        color: '#396de0',
        fontSize: 18,
        fontWeight: 'bold',
      },
      priceText: {
        fontSize: 18,
        fontFamily: "'lato', sans-serif",
        color: '#000000',
      },
      paymentText: {
        fontWeight: 'bold',
        fontSize: 18,
        marginLeft: 20,
      },
      cardsDiv: {
        marginLeft: 15,
      }
});

const StripeForm = ({updateCard}) => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { error, paymentIntent } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: "https://business.dupephotos.com/account",
            },
            redirect: "if_required",
        });

        if (error) {
            console.error(error);
            // handleError();
        } else {
            updateCard();
            // handleOther();
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <button disabled={!stripe}>Submit</button>
        </form>
    )
}

export default Account
