import React, {Fragment, useState} from 'react'
import "../stylesheets/CreateCampaign.css";
import instagramIcon from "../icons/DupeIcons-09-Instagram.png";
import tiktokIcon from "../icons/DupeIcons-10-TikTok.png";
import pencilIcon from "../icons/pencil.png";
import {createCampaign, createCampaignAttachment, createCampaignImage} from "../services/CampaignService";
import {useNavigate} from "react-router-dom";


const CreateCampaign = () => {
    const campaignOverviewString = "campaign-overview";
    const campaignDetailsString = "campaign-details";
    const toneAndGuidelinesString = "tone-and-guidelines";
    const notificationsString = "notifications";
    const compensationString = "compensation";
    const deliverablesString = "deliverables";
    const trackingString = "tracking";
    const [currentPage, setCurrentPage] = useState(campaignOverviewString);
    const [showNewTask, setShowNewTask] = useState(false);
    const [newTask, setNewTask] = useState({});
    const [campaign, setCampaign] = useState({});
    const navigate = useNavigate();

    function setCoverImage(file) {
        setCampaign((prevState) => {return({...prevState, cover_img: file})});

    }

    function setAttachment(file) {
        setCampaign((prevState) => {return({...prevState, attachment: file})});

    }

    function CampaignOverview() {
        return (
            <div>
                <div className={"third-col"}>
                    <div className={"white-col-div"}>
                        <h5 className={"blue-text"}>Campaign Cover Preview</h5>
                        {campaign.cover_img && (
                            <div>
                                <img className={"campaign-cover-img"} alt="not fount" src={URL.createObjectURL(campaign.cover_img)}/>
                            </div>
                        )}
                        {!campaign.cover_img && (
                            <div>
                                <p>Upload Cover Image</p>
                                <input type="file" className="form-control" id="exampleInputPassword1"
                                       onChange={(event) => {
                                           setCoverImage(event.target.files[0]);
                                       }}/>
                            </div>
                        )}
                    </div>
                    <div className={"white-col-div"}>
                        <h5 className={"blue-text"}>Attachment</h5>
                        {!campaign.attachment && (
                            <div>
                                <p>Upload Cover Image</p>
                                <input type="file" className="form-control" id="exampleInputPassword1"
                                       onChange={(event) => {
                                           setAttachment(event.target.files[0]);
                                       }}/>
                            </div>
                        )}
                        {campaign.attachment && (
                            <div>
                                <p>{campaign.attachment.name}</p>
                            </div>
                        )}
                    </div>

                </div>
                <div className={"two-third-col"}>
                    <h5 className={"blue-text"}>Campaign Overview</h5>
                    <form className={"flex-form"}>
                        <div className="form-group">
                            <div>
                                <p className={"font-bold"}>Private - Influencers can't apply</p>
                                <p>Your campaign won't appear in campaign searches. Only creators that have already applied or have een added to your campaign can view it.</p>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className={"font-bold"} htmlFor="exampleInputPassword1">Campaign Name</label>
                            <input value={campaign.name} onChange={event => setCampaign(prevState => ({ ...prevState, name: event.target.value}))} type="text" className="form-control" id="exampleInputPassword1" />
                        </div>
                        <div className="row">
                            <div className="col">
                                <label className={"font-bold"}>Campaign Start*</label>
                                <input value={campaign.startDate} onChange={event => setCampaign(prevState => ({ ...prevState, startDate: event.target.value}))} type="date" className="form-control" placeholder="First name" />
                            </div>
                            <div className="col">
                                <label className={"font-bold"}>Campaign End*</label>
                                <input value={campaign.endDate} onChange={event => setCampaign(prevState => ({ ...prevState, endDate: event.target.value}))} type="date" className="form-control" placeholder="Last name" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className={"font-bold"} htmlFor="exampleInputPassword1">Campaign Description*</label>
                            <textarea value={campaign.description} onChange={event => setCampaign(prevState => ({ ...prevState, description: event.target.value}))} type="text" className="form-control" id="exampleInputPassword1" />
                        </div>
                        {/*<p className={"blue-text font-bold form-divider"}>Invitation Acceptance</p>*/}
                        {/*<div className="row">*/}
                        {/*    <div className="col-5">*/}
                        {/*        <label className={"font-bold"}>End Date*</label>*/}
                        {/*        <input value={campaign.invitationAcceptanceEndDate} onChange={event => setCampaign(prevState => ({ ...prevState, invitationAcceptanceEndDate: event.target.value}))} type="date" className="form-control" placeholder="First name" />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<p className={"blue-text font-bold form-divider"}>Content Delivery</p>*/}
                        {/*<div className="row">*/}
                        {/*    <div className="col">*/}
                        {/*        <label className={"font-bold"}>Start Date*</label>*/}
                        {/*        <input value={campaign.contentDeliveryStartDate} onChange={event => setCampaign(prevState => ({ ...prevState, contentDeliveryStartDate: event.target.value}))} type="date" className="form-control" placeholder="First name" />*/}
                        {/*    </div>*/}
                        {/*    <div className="col">*/}
                        {/*        <label className={"font-bold"}>End Date*</label>*/}
                        {/*        <input value={campaign.contentDeliveryEndDate} onChange={event => setCampaign(prevState => ({ ...prevState, contentDeliveryEndDate: event.target.value}))} type="date" className="form-control" placeholder="Last name" />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </form>
                    <button onClick={saveCampaign} type="submit" className="btn blue-btn bottom-right-btn">Submit</button>
                </div>
            </div>
        );
    }

    function CampaignDetails() {
        return (
            <div className={"white-container-div full-page"}>
                <h5 className={"blue-text"}>Campaign Details</h5>
                <form className={"flex-form"}>
                    <div className="form-group">
                        <label className={"font-bold"} htmlFor="exampleInputPassword1">Campaign Preview</label>
                        <input value={campaign.preview} onChange={event => setCampaign(prevState => ({ ...prevState, preview: event.target.value}))} type="text" className="form-control" id="exampleInputPassword1" />
                    </div>
                    <div className="form-group">
                        <label className={"font-bold"} htmlFor="exampleInputPassword1">Campaign Description*</label>
                        <textarea value={campaign.description} onChange={event => setCampaign(prevState => ({ ...prevState, description: event.target.value}))} type="text" className="form-control" id="exampleInputPassword1" />
                    </div>
                    <div className="form-group">
                        <label className={"font-bold"} htmlFor="exampleInputPassword1">Campaign Image</label>
                        <input type="file" className="form-control" id="exampleInputPassword1" />
                    </div>
                </form>
                <button onClick={event => setCurrentPage(campaignOverviewString)} type="submit" className="btn blue-inverse-btn bottom-left-btn">Back</button>
                <button onClick={event => setCurrentPage(toneAndGuidelinesString)} type="submit" className="btn blue-btn bottom-right-btn">Next</button>
            </div>
        );
    }

    function ToneAndGuidelines() {
        return (
            <div className={"white-container-div full-page"}>
                <h5 className={"blue-text"}>Tone and Guidelines</h5>
                <form className={"flex-form"}>
                    <div className="form-group">
                        <label className={"font-bold"} htmlFor="exampleInputPassword1">Tone and Positioning</label>
                        <input value={campaign.toneAndPositioning} onChange={event => setCampaign(prevState => ({ ...prevState, toneAndPositioning: event.target.value}))} type="text" className="form-control" id="exampleInputPassword1" />
                    </div>
                    <div className="form-group">
                        <label className={"font-bold"} htmlFor="exampleInputPassword1">Important Guidelines</label>
                        <textarea value={campaign.guidelines} onChange={event => setCampaign(prevState => ({ ...prevState, guidelines: event.target.value}))} type="text" className="form-control" id="exampleInputPassword1" />
                    </div>
                    <div className="form-group">
                        <label className={"font-bold"} htmlFor="exampleInputPassword1">Publishing Notes</label>
                        <textarea value={campaign.publishingNotes} onChange={event => setCampaign(prevState => ({ ...prevState, publishingNotes: event.target.value}))} type="text" className="form-control" id="exampleInputPassword1" />
                    </div>
                    <div className="form-group">
                        <label className={"font-bold"} htmlFor="exampleInputPassword1">Campaign Inspo Photos</label>
                        <input type="file" className="form-control" id="exampleInputPassword1" />
                    </div>
                    <div className="form-group">
                        <label className={"font-bold"} htmlFor="exampleInputPassword1">Campaign Brief</label>
                        <input type="file" className="form-control" id="exampleInputPassword1" />
                    </div>
                </form>
                <button onClick={event => setCurrentPage(campaignOverviewString)} type="submit" className="btn blue-inverse-btn bottom-left-btn">Back</button>
                <button onClick={event => setCurrentPage(notificationsString)} type="submit" className="btn blue-btn bottom-right-btn">Next</button>
            </div>
        )
    }

    function Notifications() {
        return (
            <div className={"white-container-div full-page"}>
                <h5 className={"blue-text"}>Notifications</h5>
                <form className={"flex-form"}>
                    <div className="form-group">
                        <label className={"font-bold"} htmlFor="exampleInputPassword1">Email Notifications for Applications and Invitations*</label>
                        <div className={"row"}>
                            <div className={"col notification-border"}>
                                <div className="form-check">
                                    <input onChange={event => setCampaign(prevState => ({ ...prevState, notificationSetting: true}))} className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                        Send email notifications
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input onChange={event => setCampaign(prevState => ({ ...prevState, notificationSetting: false}))} className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" />
                                    <label className="form-check-label" htmlFor="exampleRadios2">
                                        Don't send email notifications
                                    </label>
                                </div>
                            </div>
                            <div className={"col"}>
                                <p>Get an email when an Influencer applies to your campaign or responds to an invitation. This email will be sent to the address of your Dupe account.</p>
                            </div>
                        </div>
                    </div>
                </form>
                <button onClick={event => setCurrentPage(toneAndGuidelinesString)} type="submit" className="btn blue-inverse-btn bottom-left-btn">Back</button>
                <button onClick={event => setCurrentPage(compensationString)} type="submit" className="btn blue-btn bottom-right-btn">Next</button>
            </div>
        );
    }

    function Compensation() {
        return (
            <div className={"white-container-div full-page"}>
                <h5 className={"blue-text"}>Compensation</h5>
                <form className={"flex-form"}>
                    <label className={"font-bold"} htmlFor="exampleInputPassword1">Compensation Method*</label>
                    <div className="form-group">
                        <div className="form-check form-check-inline">
                            <input value={campaign.paymentCompensation} onChange={event => setCampaign(prevState => ({ ...prevState, paymentCompensation: event.target.checked}))} className="form-check-input" type="checkbox" id="inlineCheckbox1"/>
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Payment</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input value={campaign.productCompensation} onChange={event => setCampaign(prevState => ({ ...prevState, productCompensation: event.target.checked}))} className="form-check-input" type="checkbox" id="inlineCheckbox2" />
                                <label className="form-check-label" htmlFor="inlineCheckbox2">Product</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input value={campaign.commissionCompensation} onChange={event => setCampaign(prevState => ({ ...prevState, commissionCompensation: event.target.checked}))} class="form-check-input" type="checkbox" id="inlineCheckbox3" />
                                <label class="form-check-label" for="inlineCheckbox3">Commission</label>
                        </div>
                    </div>
                    <p className={"blue-text font-bold form-divider"}>Payment</p>
                    <div className="row">
                        <div className={"col-5"}>
                            <label className={"font-bold"} htmlFor="exampleInputPassword1">Budget Per Influencer</label>
                            <input value={campaign.budgetPerInfluencer} onChange={event => setCampaign(prevState => ({ ...prevState, budgetPerInfluencer: event.target.value}))} type="text" className="form-control" id="exampleInputPassword1" />
                        </div>
                        <div className={"col-5"}>
                            <label className={"font-bold"} htmlFor="exampleInputPassword1">Allow Influencers Propose Own Price?</label>
                            <div className="form-check form-check-inline">
                                <input value={campaign.influencerProposalAccepted} onChange={event => setCampaign(prevState => ({ ...prevState, influencerProposalAccepted: true}))} className="form-check-input" type="checkbox" id="inlineCheckbox1" />
                                    <label className="form-check-label" htmlFor="inlineCheckbox1">Yes</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input value={campaign.influencerProposalAccepted} onChange={event => setCampaign(prevState => ({ ...prevState, influencerProposalAccepted: false}))} className="form-check-input" type="checkbox" id="inlineCheckbox2" />
                                    <label class="form-check-label" for="inlineCheckbox2">No</label>
                            </div>
                        </div>
                    </div>
                    <p className={"blue-text font-bold form-divider"}>Product</p>
                    <div className="form-group">
                        <label className={"font-bold"} htmlFor="exampleInputPassword1">Product Details</label>
                        <textarea value={campaign.productDetails} onChange={event => setCampaign(prevState => ({ ...prevState, productDetails: event.target.value}))} type="text" className="form-control" id="exampleInputPassword1" />
                    </div>
                    <p className={"blue-text font-bold form-divider"}>Commission</p>
                    <div className="form-group">
                        <label className={"font-bold"} htmlFor="exampleInputPassword1">Commission Details*</label>
                        <textarea value={campaign.commissionDetails} onChange={event => setCampaign(prevState => ({ ...prevState, commissionDetails: event.target.value}))} type="text" className="form-control" id="exampleInputPassword1" />
                    </div>
                </form>
                <button onClick={event => setCurrentPage(notificationsString)} type="submit" className="btn blue-inverse-btn bottom-left-btn">Back</button>
                <button onClick={event => setCurrentPage(deliverablesString)} type="submit" className="btn blue-btn bottom-right-btn">Next</button>
            </div>
        );
    }

    function openNewTask() {
        setShowNewTask(true);
    }

    function saveTask() {
        if (campaign.deliverables) {
            campaign.deliverables.push(newTask);
            setCampaign(campaign);
            setNewTask({});
        } else {
            campaign.deliverables = [newTask]
            setCampaign(campaign);
            setNewTask({});
        }
        setShowNewTask(false);
    }

    function cancelTask() {
        setShowNewTask(false);
    }

    function removeTask(task, event) {
        event.preventDefault();
        const newTasks = campaign.deliverables.filter(existingTask => {
            if (existingTask.name === task.name
                && existingTask.network === task.network
                && existingTask.creativeReq === task.creativeReq
                && existingTask.required === task.required) {
                return false;
            } else {
                return true;
            }
        });
        setCampaign(prevState => ({
            ...prevState,
            deliverables: newTasks
        }));
    }
    function Deliverables() {
        return (
            <div className={"white-container-div full-page"}>
                <h5 className={"blue-text"}>Deliverables</h5>
                <form className={"flex-form"}>
                    <div className="form-group">
                        <label className={"font-bold"} htmlFor="exampleInputPassword1">Deliverables Description</label>
                        <textarea type="text" className="form-control" id="exampleInputPassword1" />
                    </div>
                    <p className={"blue-text font-bold form-divider"}>Deliverables Task</p>
                    {!showNewTask && (
                        <Fragment>
                            <table className={"task-table"}>
                                <tr>
                                    <th></th>
                                    <th>Task</th>
                                    <th>Required</th>
                                    <th></th>
                                </tr>
                                {campaign.deliverables && campaign.deliverables.map(task => (
                                    <tr>
                                        <td></td>
                                        {task.network === "Instagram" && (
                                            <td><img className={"task-network-icon-img"} src={instagramIcon} />{task.name}</td>
                                        )}
                                        {task.network === "TikTok" && (
                                            <td><img className={"task-network-icon-img"} src={tiktokIcon} />{task.name}</td>
                                        )}
                                        <td>{task.required}</td>
                                        <td>
                                            <img src={pencilIcon} className={"edit-task-img"} />
                                            <button className={"btn btn-danger"} onClick={event => removeTask(task, event)}>Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </table>
                            <button onClick={openNewTask} className={"new-deliverable-btn"}>+ New Deliverables</button>
                        </Fragment>
                    )}
                    {showNewTask && (
                        <Fragment>
                            <div className="form-group">
                                <label className={"font-bold"} htmlFor="exampleInputPassword1">Select Network</label>
                                <select className="form-control" id="exampleFormControlSelect1" value={newTask.network}
                                        onChange={event => setNewTask(prevState => ({ ...prevState, network: event.target.value}))}>
                                    <option>Instagram</option>
                                    <option>Facebook</option>
                                    <option>TikTok</option>
                                    <option>Pinterest</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label className={"font-bold"} htmlFor="exampleInputPassword1">Task Name</label>
                                <input type="text" className="form-control" id="exampleInputPassword1" value={newTask.name}
                                       onChange={event => setNewTask(prevState => ({ ...prevState, name: event.target.value}))} />
                            </div>
                            <div className="form-group">
                                <label className={"font-bold"} htmlFor="exampleInputPassword1">Creative Requirements</label>
                                <textarea type="text" className="form-control" id="exampleInputPassword1" value={newTask.creativeReq}
                                          onChange={event => setNewTask(prevState => ({ ...prevState, creativeReq: event.target.value}))}/>
                            </div>
                            <label className={"font-bold"} htmlFor="exampleInputPassword1">Is this Task Required?</label>
                            <div className="form-group">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value={newTask.required}
                                           onChange={event => setNewTask(prevState => ({ ...prevState, required: true}))} />
                                        <label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value={newTask.required}
                                           onChange={event => setNewTask(prevState => ({ ...prevState, required: false}))} />
                                        <label class="form-check-label" for="inlineRadio2">No</label>
                                </div>
                            </div>
                        </Fragment>
                    )}
                </form>
                {!showNewTask && (
                    <Fragment>
                        <button onClick={event => setCurrentPage(compensationString)} type="submit" className="btn blue-inverse-btn bottom-left-btn">Back</button>
                        <button onClick={event => setCurrentPage(trackingString)} type="submit" className="btn blue-btn bottom-right-btn">Next</button>
                    </Fragment>
                )}
                {showNewTask && (
                    <Fragment>
                        <button onClick={cancelTask} type="submit" className="btn blue-inverse-btn bottom-left-btn">Cancel</button>
                        <button onClick={saveTask} type="submit" className="btn blue-btn bottom-right-btn">Save</button>
                    </Fragment>
                )}
            </div>
        );
    }

    function Tracking() {
        return (
            <div className={"white-container-div full-page"}>
                <h5 className={"blue-text"}>Tracking</h5>
                <form className={"flex-form"}>
                    <div className="form-group">
                        <label className={"font-bold"} htmlFor="exampleInputPassword1">Content tracking #Tags amd @Tags</label>
                        <p>Brief will read: Please remember to use these tags in your post:</p>
                        <textarea value={campaign.contentTracking} onChange={event => setCampaign(prevState => ({ ...prevState, contentTracking: event.target.value}))} type="text" className="form-control" id="exampleInputPassword1" />
                        <p>List the #'s and @'s that will be used for automatic content discovery. Note: one per line</p>
                    </div>
                    <div className="form-group">
                        <label className={"font-bold"} htmlFor="exampleInputPassword1">Extra Tags</label>
                        <textarea value={campaign.extraTags} onChange={event => setCampaign(prevState => ({ ...prevState, extraTags: event.target.value}))} type="text" className="form-control" id="exampleInputPassword1" />
                        <p>List the extra hashtags (#ad,#sponsored) that you would like the creator to use. Tags listed here are not tracked and content will not be discovered based on these tags. Note: one per line</p>
                    </div>
                </form>
                <button onClick={event => setCurrentPage(notificationsString)} type="submit" className="btn blue-inverse-btn bottom-left-btn">Back</button>
                <button onClick={saveCampaign} type="submit" className="btn blue-btn bottom-right-btn">Submit</button>
            </div>
        );
    }

    function saveCampaign() {
        const token = localStorage.getItem("token");
        let campaignReq = {};
        if (campaign.startDate) {
            campaignReq.startDate = campaign.startDate + "T00:00:00Z";
        }
        if (campaign.endDate) {
            campaignReq.endDate = campaign.endDate + "T00:00:00Z";
        }
        campaignReq.name = campaign.name;
        campaignReq.description = campaign.description;
        if (campaign.cover_img) {
            let formData = new FormData();
            formData.append("img", campaign.cover_img);
            createCampaignImage(formData, token)
                .then(resp => {
                    if (resp.status === 200 && resp.data && resp.data.img_id) {
                        campaignReq.imageId = resp.data.img_id;
                    }
                    let fileData = new FormData();
                    fileData.append("file", campaign.attachment);
                    createCampaignAttachment(fileData, token)
                        .then(attachResp => {
                            if (attachResp.status === 200 && attachResp.data) {
                                campaignReq.briefId = attachResp.data.id
                            }
                            createCampaign(campaignReq, token)
                                .then(resp => {
                                    navigate('/outreach');
                                })
                        })
                })
        } else {
            createCampaign(campaignReq, token)
                .then(resp => {
                    navigate('/outreach');
                })
        }
    }

    return(
        <div>
            <div className={"nav-header-div"}>
                <h4 className={"font-bold"}>Campaigns</h4>
            </div>
            {CampaignOverview()}
        </div>

    );
}

export default CreateCampaign
